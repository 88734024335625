<template>
  <div
    class="wrap_box"
    style="width:100%"
  >
    <echart
      :config="echartConfig"
      :option="echartOption"
      v-if="echartOption"
    />
  </div>
</template>
<script>
import echart from '@/libs/components/echart/echart'
import json from './json'
export default {
  name: 'ClassAA',
  components: {
    echart
  },
  data () {
    return {
      echartData: [],
      echartOption: '',
      echartConfig: {
        id: 'class',
        setStyle: { width: '95%', height: '95%', margin: '2.5rem auto 0' },
      }
    }
  },
  async mounted () {
    if (this.$root.libs.data.getSession('isClear') === 'N' && this.$route.query.autoNav === 'true') {
      this.$root.libs.data.setSession('isClear', 'Y')
      window.location.reload(0)
      return
    }
    await this.init()
    const px = window.innerWidth / 120 / 16
    let echartData = this.echartData
    this.echartOption = {
      title: {
        text: json.classData.title,
        textStyle: {
          color: '#fff',
          fontSize: 20 * px,
          marginBottom: 10 * px,
        }
      },
      textStyle: {
        fontSize: 18 * px
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: echartData.data.filter(item => item.name),
        textStyle: {
          color: '#fff',
          fontSize: 16 * px
        },
        selectedMode: false
      },
      grid: {
        top: '6%',
        left: 0,
        right: 0,
        bottom: '2%',
        containLabel: true
      },
      yAxis: {
        type: 'value',
        nameTextStyle: {
          padding: [0, 0, 10, 0]
        },
        boundaryGap: [0, 0.01],
        axisLabel: {
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 16 * px
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#fff',
            width: 2,
          }
        }
      },
      xAxis: {
        type: 'category',
        data: echartData.label,
        axisLabel: {
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 16 * px
          }
        }
      },
      color: ['#55FEFB', '#A364D1', '#f6b66b'],
      series: echartData.data,
      ...json.classData.option
    }
  },
  created () {
    // this.init()
  },
  methods: {
    async init () {
      if (this.$route.query.demo !== 'Y') {
        let userInfo = JSON.parse(this.$root.libs.data.getStorage('userInfo'))
        let res = await this.$root.libs.request(
          this.$root.libs.api.ssyypt.training_recipe_running_web
            .queryWorkoutGenderCorrelation, { limit: 5 }, { headers: { jwt: userInfo.jwt } }
        )
        res = res.data

        let dataFormat = await this.dataFormat(res)
        let data = {
          title: '治疗计划与性别关联度',
          label: dataFormat.map(item => item.workoutName),
          data: [
            {
              name: '男性',
              data: dataFormat.map(item => item.male.workoutTotal)
            },
            {
              name: '女性',
              data: dataFormat.map(item => item.famale.workoutTotal)
            },
            {
              name: '未知',
              data: dataFormat.map(item => item.unknow.workoutTotal)
            }
          ],
          option: { title: { text: '治疗计划与性别关联度' } }
        }
        this.echartData = data
      } else this.echartData = json.classData

      this.echartData.data.forEach(item => {
        if (!item.type) item.type = 'bar'
        if (!item.label)
          item.label = { show: true, position: 'top', color: '#fff' }
        item.barMaxWidth = '180'
      })
    },
    dataFormat (res) {
      let data = res.map(item => {
        let unknow = item.genderAmountDto.filter(mItem => mItem.gender === '0' || !mItem.gender)
        if (unknow.length > 0) {
          let total = 0
          unknow.forEach(item => total += item.workoutTotal)
          unknow = { gender: '0', workoutTotal: total }
        } else unknow = { gender: '0', workoutTotal: 0 }
        let dataFormat = {
          workoutName: item.workoutName,
          male: item.genderAmountDto.find(mItem => mItem.gender === '1') || { gender: '1', workoutTotal: 0 },
          famale: item.genderAmountDto.find(mItem => mItem.gender === '2') || { gender: '2', workoutTotal: 0 },
          unknow,
        }
        return dataFormat
      })
      return data
    }
  }
}
</script>
